import { render, staticRenderFns } from "./Registration.vue?vue&type=template&id=5811ef5c&scoped=true&"
import script from "./Registration.vue?vue&type=script&lang=js&"
export * from "./Registration.vue?vue&type=script&lang=js&"
import style0 from "./Registration.vue?vue&type=style&index=0&id=5811ef5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5811ef5c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VStepper,VStepperContent,VStepperItems})
