<template>
  <div>
    <v-stepper
      v-model="currentStep"
      class="stepper"
    >
      <form
        name="registration"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        @submit.prevent
      >
        <input
          type="hidden"
          name="form-name"
          value="registration"
        >
        <v-stepper-items>
          <v-stepper-content
            v-for="(question, $index) in form"
            :key="$index"
            :step="$index"
          >
            <h3
              v-if="question.inputType"
              style="color: black;padding-bottom: 15px;"
            >
              Please complete each step below to register. ({{ $index + 1 }} of {{ form.length }})
            </h3>
            <v-card
              class="white--text mb-12 input-container"
              color="grey lighten-3"
              :width="$vuetify.breakpoint.mobile ? 300 : 550"
            >
              <component
                :is="question.inputType"
                v-if="question.inputType"
                class="input"
                :name="question.key"
                :items="question.options"
                outlined
                required
                :label="question.label"
                background-color="white"
                @input="question.value = $event"
                @change="question.value = $event"
                @keyup.enter="next(question)"
              />
              <v-card-subtitle
                v-else
                class="red--text"
              >
                {{ question.label }} 💩
              </v-card-subtitle>
            </v-card>

            <v-btn
              v-if="!formCompleted"
              color="primary"
              :disabled="questionAnswerValid(question)"
              @click="next(question)"
            >
              Continue
            </v-btn>

            <v-btn
              v-if="currentStep > 0 && !formCompleted"
              text
              @click="currentStep = currentStep - 1"
            >
              Back
            </v-btn>
            <v-btn
              v-if="formCompleted"
              color="primary"
              to="/"
            >
              Return to Race Info.
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
        <input
          type="file"
          hidden
          name="madLibsResults"
        >
      </form>
    </v-stepper>
    <v-card
      v-if="formCompleted"
      :width="$vuetify.breakpoint.mobile ? 350 : 600"
    >
      <v-card-text>
        Thanks for registering!
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  VTextField, VSelect, VTextarea, VCheckbox,
} from 'vuetify/lib';
import axios from 'axios';
import html2canvas from 'html2canvas';

export default {
  name: 'Registration',
  components: {
    VTextField,
    VSelect,
    VTextarea,
    VCheckbox,
  },
  data() {
    return {
      currentStep: 0,
      madLibsResults: null,
      form: [
        {
          value: '',
          label: 'Full Name',
          key: 'FullName',
          inputType: 'VTextField',
        },
        {
          value: false,
          label: 'I will be attending the after party!',
          key: 'WillYouBeInBuffalo',
          inputType: 'VCheckbox',
        },
        {
          value: '',
          label: 'Shirt Size',
          key: 'ShirtSize',
          inputType: 'VSelect',
          options: [
            'Ladies XS',
            'Ladies S',
            'Ladies M',
            'Ladies L',
            'Ladies XL',
            'Ladies XXL',
            'Men\'s XS',
            'Men\'s S',
            'Men\'s M',
            'Men\'s L',
            'Men\'s XL',
            'Men\'s XXL',
          ],
        },
      ],
    };
  },
  computed: {
    madlibFields() {
      return this.form
        .filter((f) => f.madlib)
        .reduce((aggregate, currentValue) => ({
          ...aggregate,
          [currentValue.key]: currentValue.value,
        }), {});
    },
    computedForm() {
      return this.form
        .filter((f) => f.key)
        .map((f) => ({ key: f.key, value: f.value }))
        .reduce((aggregate, current) => ({
          ...aggregate,
          [current.key]: current.value,
        }), { madLibResults: this.madLibsResults });
    },
    formCompleted() {
      return this.currentStep === this.form.length;
    },
  },
  methods: {
    questionAnswerValid(question) {
      if (question.inputType !== 'VCheckbox') {
        return !(!question.inputType || question.value);
      }
      return false;
    },
    next(question) {
      if (this.questionAnswerValid(question)) {
        return;
      }
      this.currentStep += 1;
      if (this.formCompleted) {
        this.submit();
      }
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
        )
        .join('&');
    },
    async submit() {
      await this.$nextTick(async () => {
        // await this.generateImage();
        const axiosConfig = {
          header: { 'Content-Type': 'application/x-www-form-urlencoded' },
        };
        axios.post(
          '/',
          // 'https://compassionate-benz-6fcddc.netlify.app/',
          this.encode({
            'form-name': 'registration',
            ...this.computedForm,
          }),
          axiosConfig,
        );
      });
    },
    async generateImage() {
      const canvas = await html2canvas(document.querySelector('#mad-lib-results'));
      this.madLibsResults = canvas.toDataURL();
    },
    async downloadResults() {
      await this.generateImage();
      const link = document.createElement('a');

      link.href = this.madLibsResults;
      link.download = 'madelibs-results.png';

      // Firefox requires the link to be in the body
      document.body.appendChild(link);

      // simulate click
      link.click();

      // remove the link when done
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  margin-bottom: 30px;;
}
.input-container {
  padding: 30px;
  ::v-deep {
    .v-label.v-label--active.theme--light {
      background-color: white;
      border-radius: 3px;
      padding: 5px;
    }
  }

}
.input {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
